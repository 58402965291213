.container {
  width: 100%;
  max-width: 60%;
  min-width: 320px;
  margin: auto;
  margin-top: 10vh;
  padding: 0 20px;
}

.title {
  color: #720F83;
  text-align: center;
  font-weight: 400;
}

.notification-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 20px;
  box-sizing: border-box;
}
.divider {
  margin-bottom: 10vh;
  width: 10%;
  height: 4px; /* Height of the line */
  background-color: #720F83; /* Color of the line */
  border: none;
  border-radius: 5px;
}

.notification-form h1 {
  margin-bottom: 0;
  font-weight: 700;
}

.device-token-select,
.notification-input,
.notification-textarea {
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #720F83;
  border-radius: 15px;
  background-color: white;
  color: #3d393d;
  font-size: 1rem;
}

.device-token-select {
  color: white;
  font-weight: 600;
  background-color: #720F83;
}

.notification-textarea {
  min-height: 200px;
  resize: vertical;
}

.button {
  border: none;
  color: white;
  padding: 14px 20px;
  text-align: center;
  display: inline-block;
  font-size: 1rem;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 15px;
  width: 100%;
  max-width: 600px;
  font-weight: 700;
  box-shadow: 2px 2px 2px #9a9ca3;
  background: linear-gradient(to right, #1D3AAB, #880098);
}

.button:hover {
  background: linear-gradient(to right, #880098, #1D3AAB);
}

button:disabled {
  color: white;
  cursor: not-allowed;
  opacity: 0.4; 
}

.loadingButton {
  border: none;
  text-align: center;
  display: inline-block;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 15px;
  width: 100%;
  max-width: 600px;
  box-shadow: 2px 2px 2px #898a8d;
  background: linear-gradient(to right, #1D3AAB, #880098);
  max-height: 43px;
}

.loadingIcon {
  width: 43px;
  height: 43px;
  margin: 0;
}

.error {
  color: red;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .container {
    max-width: 80%;
    margin-top: 8vh;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  .divider {
    width: 15%;
    
  }
  .button, .loadingButton {
    padding: 12px 16px;
    font-size: 0.9rem;
  }

  .notification-form h1 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .container {
    max-width: 90%;
    margin-top: 6vh;
  }

  .button, .loadingButton {
    padding: 10px 14px;
    font-size: 0.8rem;
  }

  .notification-form h1 {
    font-size: 1.8rem;
  }
}
